.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #d9f0f9;
  color: #025595;
  border-radius: 3px;
  width: 100%;
  height: 35px;
  position: relative;
  z-index: 50;
  padding: 1em 0;
}
h4,
.arrow {
  margin: 3%;
  cursor: pointer;
  color: #025595;
}

.arrow-cliked {
  margin: 3%;
  width: 1.5em;
  animation: turn 0.5s;
  cursor: pointer;
}

.description {
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  text-align: start;
}

@keyframes turn {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.collapse {
  text-align: justify;
  padding: 1rem 1rem;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  margin-top: 0;
  margin-bottom: 2em;
  overflow: hidden;
  animation: slideup 0.2s ease-in-out forwards;
}

@keyframes slideup {
  0% {
    padding: 1rem 1.2rem;
    background: #f6f6f6;
    color: #d9f0f9;
  }
  50% {
    padding: 0.5rem 1.2rem;
    background: #f6f6f6;
    color: #f6f6f6;
  }
  100% {
    padding: 0;
    height: 0;
    background: #f6f6f6;
  }
}

.show {
  max-height: 500px;
  background: hsl(206deg 60.39% 77.74% / 12%);
  color: #025595;
  animation: slidedown 0.2s ease-in forwards;
}

@keyframes slidedown {
  0% {
    color: #025595;
    padding: 0;
  }

  50% {
    padding-to: 1rem;
    background: #f6f6f6;
    color: #025595;
  }
  100% {
    height: auto;
    padding: 2em 1.2rem;
  }
}
.dropdown {
  margin: 1em 0 1em 0;
}

/* Styles pour les petits écrans (moins de 768px de large)  */
@media screen and (max-width: 768px) {
  .title {
    padding: 1.5em 0;
  }
}
