.contact-container {
  background-image: url("/public/images/contact_compress.png");
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}

.contact-container form {
  width: 50%;
  margin-left: 8%;
  padding-bottom: 8%;
}
.container img {
  width: 14%;
}

.contact-form {
  width: 38%;
  padding: 0 8%;
}

.contact-container h2 {
  color: #025595;
}

.form-group {
  margin-bottom: 15px;
  width: 60%;
}

.form-name input {
  width: 89%;
  margin: 0;
}

.form-group-name {
  display: flex;
  flex-direction: row;
  width: 106%;
  margin-bottom: 5%;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  margin: 5px;
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.contact-image {
  max-width: 400px;
  max-height: 100%;
}

.contact-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

.mention-rgpd {
  display: flex;
}

form .CTA {
  width: 10em;
}
.valiation-error {
  color: red;
  font-style: italic;
}

.valiation {
  color: green;
}
@media (max-width: 767px) {
  .contact-form {
    width: 80%;
  }

  .contact-container form {
    width: 80%;
  }
  .form-group-name {
    flex-direction: column;
  }
  .form-name input {
    margin-bottom: 15px;
    width: 96%;
  }
  .form-group {
    width: 101%;
  }
}
