.inner_page {
  display: flex;
  flex-direction: row;
  margin: 4% 8%;
}
.inner_banner {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-bottom: 0;
}

.inner_banner_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
  margin: 6% 8% 0px 8%;
  padding-bottom: 6%;
}

.inner_banner h1 {
  color: #025595;
  font-family: "Open Sans", Helvetica;
  text-align: center;
  border-radius: 8px;
  font-size: 3.5em;
  margin: 0.24em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  display: inline-block;
}

.inner_banner p {
  text-align: center;
  line-height: 24.3px;

  width: 80%;
}

.inner_page-image {
  max-width: 40%;
}

.inner_page img {
  height: 20em;
  width: 15em;
  border-radius: 16px;
  object-fit: cover;
}

.inner_page-description {
  margin-left: 3em;
}

.inner_page-description p {
  text-align: justify;
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24.3px;
}

.inner_page-image h2 {
  color: #025595;
  font-family: "Open Sans", Helvetica;
  font-weight: 700;
  letter-spacing: -3.36px;
  width: 100%;
  margin-top: 0;
}

.Drop-menus {
  margin: 0% 0% 0% 8%;
}

@media (max-width: 767px) {
  .inner_banner {
    height: 100%;
    padding-bottom: 10%;
  }
  .inner_page {
    flex-direction: column;
    align-items: center;
  }
  .inner_page-image {
    max-width: 100%;
  }

  .inner_page-description {
    margin-left: 0em;
  }
}
