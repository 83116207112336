.cookie-consent {
  position: fixed;
  width: 100%;
  background-color: rgb(189, 195, 199);
  margin: 0px;
  left: 0px;
  bottom: 0px;
  padding: 4px;
  z-index: 1000;
  text-align: center;
}
