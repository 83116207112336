.modify-section {
  padding: 8%;
  border-right: 1px #3e3d3f solid;
}

.modify-section h1 {
  font-family: "Inter", sans-serif;
  color: #3e3d3f;
}

.btn-modify {
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
  padding: 5% 9%;
  border: 2px solid;
  color: #025595;
  border-radius: 20px;
  margin: 15px 0;
}

.btn-modify:hover {
  background-color: #025595;
  color: #ffffff;
}

.btn-delete:hover {
  background-color: #b90202;
  color: #ffffff;
}

.btn-delete {
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
  padding: 5% 9%;
  border: 2px solid;
  text-align: center;
  color: #b90202;
  border-radius: 30px;
  margin: 15px 0;
}

.btn-not-delete {
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
  padding: 0% 2%;
  border: 2px solid;
  color: #038f0e;
  border-radius: 20px;
  margin: 15px 2%;
}
.btn-not-delete:hover {
  background-color: #038f0e;
  color: #ffffff;
}

@media (max-width: 767px) {
  .modify-content {
    flex-direction: column;
    width: 100%;
  }
  .article-title,
  .article-content,
  .author,
  .article-input {
    width: 92%;
  }
}
