.nav {
  height: 96px;
  position: relative;
  width: 96%;
  padding: 0 2%;
  background-color: #ffffff00;
  box-shadow: 0.1px 0.2px 10px #6d6d6d4e;
}
.nav_logo {
  width: 30%;
}
.nav_burger {
  cursor: pointer;
  color: #025595;
  margin-right: 1rem;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
a:link {
  text-decoration: none;
}

.nav_burger a:visited {
  text-decoration: none;
  color: #025595;
}

.nav .navigation {
  height: 96px;
  width: 100%;
}

.nav .frame {
  box-shadow: var(--navbar-shadow);
  height: 96px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.nav .normal {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.small {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: center;
}
@media screen and (max-width: 767.98px) {
  .small {
    flex-direction: column;
    margin-left: 0;
    position: absolute;
    right: 0;
    top: 110%;
    visibility: hidden;
  }

  .nav .normal {
    align-items: flex-start;
    background-color: #d9edf8de;
    flex-direction: column;
    gap: 20px;
    left: 0;
    padding: 13px 0 5% 4%;
    position: absolute;
    margin-left: 17%;
    top: 95px;
    width: 12em;
  }
}

.nav .option {
  color: #025595;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
}

.option-wrapper:hover {
  background-color: #d7ebf6;
  padding: 1%;
  border-radius: 5px;
}

.nav .div {
  color: #000000;
  font-family: "Actor", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.nav .adjustable-primary {
  background-color: #025595;
  border-radius: 200px;
  height: 52px;
  width: 141px;
}
.btn {
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
  padding: 0% 2%;
  border: 2px solid;
  color: #025595;
  border-radius: 5px;
}

.btn:hover {
  background-color: #d7ebf6;
}

button {
  color: #025595;
  height: 40px;
  font-size: 0.9em;
  border: none;
  background-color: #ffffff00;
  cursor: pointer;
}
