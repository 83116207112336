.error-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
}
.error-section h1 {
  font-family: "Inter", sans-serif;
  color: #3e3d3f;
}

.back:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
}
