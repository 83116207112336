.Article {
  margin: 3% 8%;
  color: #3e3d3f;
}
.Article-content p {
  text-align: justify;
}

.Article-hearder {
  color: #025595;
  font-family: "Open Sans", Helvetica;
  font-size: 1.5em;
  font-weight: 700;
  width: 100%;
}

.Article-image {
  width: 100%;
  height: 25em;
  object-fit: cover;
}

.Article-image-default {
  width: 34%;
  height: 15em;
  padding: 4% 33%;
}

.Article-info {
  display: flex;
  width: 96%;
  justify-content: space-between;
}

.Article-date,
.Article-author {
  color: #3e3d3f;
  font-weight: 700;
  font-family: "Open Sans", Helvetica;
  font-size: 0.6em;
}
