body {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.banner-description h1 p {
  color: #025595;
  font-family: "Open Sans", Helvetica;
}

p,
label {
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  color: #3e3d3f;
  line-height: 24.3px;
}

h2 {
  font-size: 3.5em;
}

h2,
h3,
h4,
h5 {
  font-family: "Actor", Helvetica;
}

a:visited {
  text-decoration: none;
  color: black;
}

.banner {
  background-image: url("/public/images/accueil.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-bottom: 15%;
}

.banner-description {
  width: 40%;
  padding: 6% 7% 10%;
}

.banner-description h1 {
  color: #025595;
  font-family: "Open Sans", Helvetica;
  font-size: 3.5em;
  font-weight: 700;
  letter-spacing: -3.36px;
  width: 100%;
}

.banner-description p {
  color: #025595;
  font-family: "Inter", Helvetica;
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: 0;
  width: 100%;
  line-height: 1.5;
}

.bio {
  border-radius: 16px;
  box-shadow: 0.1px 0.2px 10px #6d6d6d4e;
  width: 80%;
  height: 20%;
  display: flex;
  align-items: center;
  background: white;
  justify-content: space-between;
  margin: -16% 10% 0 10%;
}

.bio-photo {
  width: 20%;
  padding: 1%;
}

.bio-photo img {
  border-radius: 16px;
}
.bio-wrapper h2 {
  color: #000000;
  font-family: "Actor", Helvetica;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
}
.bio-wrapper {
  width: 60%;
  padding-left: 3%;
}

.bio-wrapper p {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24.3px;
  width: 80%;
}

.CTA {
  margin: 3% 0;

  background-color: #025595;
  color: white;
  width: 65px;
  padding: 0.6em;
  border-radius: 16px;
  text-align: center;
  font-family: "Open Sans", Helvetica;

  box-shadow: 0.1px 0.2px 10px #bfb59d72;
}

.CTA:hover {
  background-color: #023b68;
}

.bio img {
  width: 100%;
}

.xp {
  margin: 5% 8% 5% 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-subtile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.xp h2 {
  text-align: center;
  color: #025595;
}

.xp-wrapper {
  display: flex;
  margin-top: 5%;
  gap: 10%;
}

.xp-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 4% 3%;
  border-radius: 16px;
  box-shadow: 0.1px 0.2px 10px #6d6d6d4e;
}

.xp-card:hover {
  transition: 200ms;
  box-shadow: 0.1px 0.2px 10px #29292986;
  background-color: #d8e8f6ad;
}
.xp-card:hover svg {
  transition: 200ms;
  color: #025595;
  font-size: 5.3em;
}
.xp-card svg {
  font-size: 5em;
}
.xp-card h3 {
  text-align: center;
}
.xp-card p {
  padding: 0% 5%;
  text-align: center;
}

.xp-card a {
  text-align: center;
  text-decoration: none;
  color: #000000;
}

.rdv {
  margin: 8%;
  color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0.1px 0.2px 10px #6d6d6d4e;
  background-image: url("/public/images/rdv_compress_sombre.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2%;

  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.rdv p {
  color: white;
}

.rdv h2 {
  margin: 2%;
}

.mail,
.text {
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.contact {
  display: flex;
  align-items: center;
}

.form-Contact {
  padding: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.form-Contact ul {
  list-style: none;
  padding: 0;
}

.form-Contact label {
  font-size: 1.1rem;
}
.input,
textarea {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #e7e7e7;
  border: none;
  border-radius: 5px;
  color: #666;
  font-family: openSans-700, Arial, sans-serif;
  font-size: 1rem;
  padding: 15px 0;
  width: 100%;
  margin: 5% 0;
}

.form-Contact button {
  width: 9.5em;
  height: 3em;
  color: #e7e7e7;
}

.Privacy {
  font-size: 20px;
}
.Privacy-input {
  width: 10%;
  margin: 10px 0;
}

.blog {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7em;
  padding-top: 2em;
  background-color: #d9f0f9;
}

.blog-section {
  background-color: #f6f6f6;
  padding: 25px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 80%;
}
.blog-section-null {
  text-align: center;
  background-color: #f6f6f6;
  padding: 4%;
  border-radius: 10px;
  width: 65%;
  color: #025595;
  font-style: italic;
}

.blog h2 {
  margin-top: 0;
  text-align: center;
  color: #025595;
}

.blog p {
  margin: 0% 8% 8% 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", Helvetica;
}

.card-text {
  font-family: "Inter", Helvetica;
}

.contact-section {
  margin-top: 8%;
}

.contact-section h2 {
  color: #025595;
}

/* animation */

@keyframes slideLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
.banner-description {
  animation: slideLeft 1s ease-in-out;
}

.animer {
  opacity: 1;
}

/* responsive */
@media (max-width: 767px) {
  .banner-description {
    width: 70%;
  }
  .banner-description h1 {
    font-size: 2.5em;
  }
  .bio {
    flex-direction: column-reverse;
    align-items: center;
  }
  .bio-wrapper {
    padding-left: 0%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .bio-wrapper p {
    width: 84%;
    text-align: center;
    padding: 0 8%;
  }
  .bio-wrapper h1 {
    text-align: center;
  }
  .bio-wrapper h2 {
    text-align: center;
    padding: 0% 5%;
  }
  .bio .CTA {
    margin: 1em 0%;
  }

  .xp-wrapper {
    flex-direction: column;
  }
  .bio-photo {
    width: 40%;
    padding-top: 1em;
  }
  .xp-card {
    margin-top: 8%;
    padding: 4% 0%;
  }

  .blog-section {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
  }

  h2 {
    font-size: 2.5em;
  }
  .blog p {
    text-align: center;
  }
}
