.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  display: flex;
  background-size: cover;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding: 8% 0;
}

.login h1 {
  color: #025595;
  font-family: "Open Sans", Helvetica;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  margin: 0.24em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  display: inline-block;
}

.login h2 {
  font-size: 2em;
}

.login__header {
  width: 30%;
  margin-right: 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logo {
  width: 24%;
  height: auto;
  object-fit: cover;
  display: flex;
  align-items: center;
  margin: 3em 1em;
}
.cvtheque {
  width: 70%;
}
.login__title {
  color: rgb(0, 0, 0);
  font-size: 1.2rem;
  width: 79%;
  padding: 5%;
}

.login-page {
  width: 30%;
  height: 100%;
}

.form {
  background: #f5f5f5;
  width: auto;
  padding: 45px;
  border-radius: 5%;
}
.input-type {
  display: block;
  margin: 5%;
}

.input-type label {
  margin-right: 5%;
}
input[type="radio"] {
  margin-right: 5px;
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #ffffff;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #025595;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,
.form button:active,
.form button:focus {
  background: #013846;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #013846;
  text-decoration: none;
}
.reset-btn:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .login {
    flex-direction: column;
    height: 100%;
    padding: 15% 0;
  }
  .login__header {
    width: 100%;
    margin-right: 0;
  }
  .login-page {
    width: 100%;
  }
  .login h1 {
    padding: 0;
    width: 50%;
  }

  .form {
    margin: 8% 8% 0% 8%;
  }
}
