.footer {
  color: gray;
  padding: 1rem 1rem;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d9f0f9;
  flex-shrink: 0;
}

.footer_logo {
  width: 7em;
}

.option {
  color: #025595;
  font-family: "Inter", sans-serif;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}
.footer_socials {
  margin-left: -0.2rem;
  padding: 1rem 0;
  display: flex;
  white-space: nowrap;
}

.footer_social {
  margin-right: 1.2rem;
}

.footer svg {
  font-size: 1.3rem;
  color: #025595;
  font-family: "Inter", sans-serif;
}

.footer_links {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.footer_links .option:hover {
  color: #032c4c;
  text-decoration: underline;
}
.footer_social :hover {
  color: #032c4c;
}

.footer_copy {
  color: #025595;
  font-family: "Inter", sans-serif;
}

li .option {
  width: 100%;
  padding-top: 8px;
}
ul {
  list-style: none;
  padding: 1em;
}

@media screen and (min-width: 768px) {
  .footer_link {
    margin-top: 15px;
    flex: 1 0 25%;
  }

  .footer {
    align-items: center;
  }
}
@media (max-width: 767px) {
  .footer_links {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .footer_link {
    padding: 1%;
    flex: 0 0 calc(33.33% - 10px);
    font-size: 0.8em;
    flex-wrap: wrap;
    overflow-wrap: break-word;
    margin: 5px;
    box-sizing: border-box;
  }
  a .option {
    font-size: 1em;
    overflow-wrap: break-word;
  }

  .footer-container {
    flex-direction: column;
  }
  .footer {
    justify-content: space-evenly;
  }
  .footer_copy {
    visibility: hidden;
  }
  .footer_socials {
    padding: 20px;
  }
}
