.dashboard-title {
  margin-top: 5%;
  text-align: center;
  color: #025595;
  font-family: "Open Sans", Helvetica;
  font-size: 3.5em;
  font-weight: 700;
  letter-spacing: -3.36px;
  width: 100%;
}

.blog-null {
  margin: 14% 8%;
  background-color: #f6f6f6;
  padding: 4%;
  border-radius: 10px;
  color: #025595;
  font-style: italic;
}

.dashboard-grid {
  background-color: #f6f6f6;
  padding: 25px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 80%;
  margin: 8%;
}

.new-card-content {
  color: #025595;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.new-card {
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
  color: #025595;
  border: solid;
}

.new-card:hover {
  box-shadow: 0.1px 0.2px 10px #6d6d6d4e;
  cursor: pointer;
}

.new-card svg {
  font-size: 5em;
}

.rdv p {
  padding: 0% 5%;
  text-align: justify;
}

@media (max-width: 767px) {
  .dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
    width: auto;
  }
}
