.card {
  background-color: white;
  color: #272727;
  border-radius: 10px;
  border: 0px;
  border-style: solid;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card:hover {
  transition: 200ms;
  box-shadow: 0.1px 0.2px 10px #29292986;
  cursor: pointer;
}

.card-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0% 8%;
}

.profile {
  width: 2.5rem;
  height: 2.5rem;
  background-color: tomato;
  border-radius: 50%;
  margin: 10px;
  font-size: 20pt;
  text-align: center;
  font-weight: bold;
  color: white;
  justify-content: center;
}

.letter {
  vertical-align: middle;
}

.card-title-group {
  justify-content: center;
  padding: 14% 5%;
  width: 90%;
}

.card-title {
  flex: 0.5;
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #025595;
  margin-top: 0;
}
.card-date {
  flex: 0.3;
  font-size: small;
  margin-top: 0;
}
.card-image {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 13em;
  object-fit: cover;
  -webkit-mask-image: -webkit-gradient(
    linear,
    center bottom,
    center top,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(1, rgba(0, 0, 0, 1))
  );
}

.card:hover {
  .card-image {
    -webkit-mask-image: -webkit-gradient(
      linear,
      center bottom,
      center top,
      color-stop(0, rgba(0, 0, 0, 0)),
      color-stop(0, rgba(0, 0, 0, 1))
    );
  }
}

.card-image-default {
  padding-left: 8%;
  border-radius: 10px 10px 0 0;
  height: 11em;
  width: 70%;
  padding-top: 10%;
}

.card-like-icon {
  height: 25px;
}

.card-text {
  font-size: small;
  line-height: 1.2em;
  max-height: 7em;
  color: #272727;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: center;
  margin-top: 8%;
}

.card-like-bar {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}

.like-text {
  font-size: small;
  margin-left: 10px;
}
