.article {
  padding: 8%;
}

.article .CTA {
  width: 9em;
}
/* Styles pour la barre d'outils */
.toolbar-class {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  padding: 8px;
}

/* Styles pour l'enveloppe (wrapper) de l'éditeur */
.wrapper-class {
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}

/* Styles pour l'éditeur de texte lui-même */
.editor-class {
  border: 1px solid #ccc;
  min-height: 200px;
  padding: 10px;
  font-size: 16px;
}
