.profil {
  display: flex;
  flex-direction: row;
  margin: 3% 8% 0% 8%;
}
.Profil_banner {
  background-image: url("/public/images/BannerBis_compress.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.Profil_banner_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 6% 8% 0px 8%;
  padding-bottom: 6%;
}

.Profil_banner h1 {
  color: #025595;
  font-family: "Open Sans", Helvetica;
  text-align: center;
  border-radius: 8px;
  font-size: 3.5em;
  margin: 0.24em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  display: inline-block;
}

.about_subtile {
  padding: 4% 8% 0 8%;
  font-family: "Inter", Helvetica;
  text-align: justify;
  line-height: 24.3px;
}
.distinction {
  font-family: "Inter", Helvetica;
}

.profil-image {
  max-width: 40%;
}

.profil img {
  height: 20em;
  width: 15em;
  border-radius: 16px;
}

.profil-description {
  margin-left: 3em;
}

.profil-description h3 {
  margin: 18px 0 0 0;
}

.profil-description p {
  text-align: justify;
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24.3px;
  margin: 0%;
}

.profil-image h2 {
  color: #025595;
  font-family: "Open Sans", Helvetica;
  font-weight: 700;
  letter-spacing: -3.36px;
  width: 100%;
  margin-top: 0;
}

.profil_part2 {
  padding: 0% 8% 4% 8%;
  text-align: justify;
  font-family: "Inter", Helvetica;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24.3px;
}

.profil_part2 h3 {
  margin: 18px 0 0 0;
}

.profil_part2 p {
  margin: 0;
}

@media (max-width: 767px) {
  .profil {
    flex-direction: column;
    align-items: center;
  }
  .profil-image {
    max-width: 80%;
  }
  .profil-image h2 {
    text-align: center;
  }
  .profil img {
    height: auto;
    width: 100%;
  }

  .profil-description {
    margin-left: 0em;
  }
  .about_subtile,
  .profil_part2,
  .profil-description p {
    text-align: left;
  }
}
