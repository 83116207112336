.reset-page {
  margin: 8%;
}

.reset-form {
  background: #f5f5f5;
  width: auto;
  padding: 45px;
  border-radius: 13px;
  margin: 3% 0;
}
