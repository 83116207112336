.all-domaine {
  width: 60%;
}
.domaine-selection {
  color: #025595;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  background-color: #ffffff00;
  border: none;
  cursor: pointer;
}

.domaine-selection:hover {
  background-color: #d7ebf6;
  padding: 2px 2px;
  border-radius: 5px;
}
